import { Link } from "gatsby";
import React from "react";
import * as s from "./partners.module.css";

const Partners = ({ image, title, partners }) => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 d-none d-md-block">{!!image && image}</div>
            <div className="col-12 col-md-6 px-0">
              <h2 className={s.title}>
                {title}
              </h2>
              <div className={s.partners}>
                {partners?.length > 0 && partners.map((p,i) => {
                  if (!!p.to) {
                    return <Link to={p.to} key={i}>{p.title}</Link>
                  }
                  return <span key={i}>{p.title}</span>
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Partners;
