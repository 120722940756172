import { Link } from "gatsby";
import React from "react";
import Slider from 'react-slick';
import * as s from "./pages-list.module.css";

const PagesList = ({ title, items }) => {

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
  };

  return (
    <>
      <section className={s.pagesList}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!title &&
                <h2 className={s.title}>
                  {title}
                </h2>
              }
            </div>
          </div>
          <div className="row d-none d-md-flex">
            {items?.length > 0 && items.map((item, i) => {
              return <div className="col-12 col-md-6 mb-5" key={i}>
                <div className="row">
                  <div className="col-md-5">{item.image}</div>
                  <div className="col-md-7 d-flex flex-column justify-content-end">
                    {!!item.title && <h5 className={s.itemTitle}>{item.title}</h5>}
                    {!!item.description && <div className={s.description}>{item.description}</div>}
                    {!!item.to && <Link to={item.to} className={s.more}>Learn  More</Link>}
                  </div>
                </div>
              </div>
            })}
          </div>
          <div className="d-md-none">
            {items?.length > 0 &&
              <>
                <Slider {...sliderSettings}>
                  {items.map((item, i) => {
                    return <div className="col-12" key={i}>
                      <div className="row">
                        <div className="col-12">{item.image}</div>
                        <div className="col-12 mb-4 d-flex flex-column justify-content-end">
                          {!!item.title && <h5 className={s.itemTitle}>{item.title}</h5>}
                          {!!item.description && <div className={s.description}>{item.description}</div>}
                          {!!item.to && <Link to={item.to} className={s.more}>Learn  More</Link>}
                        </div>
                      </div>
                    </div>
                  })}
                </Slider>
              </>
            }
          </div>
        </div>
      </section>

    </>
  );
};

export default PagesList;
