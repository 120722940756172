import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Partners from "../components/partners/partners";
import Footer from "./../components/footer/footer";
import Header from "./../components/header/header";
import Integrations from "./../components/integrations/integrations";
import News from "./../components/news/news";
import Seo from "./../components/seo";
import SingleDevice from "./../components/singleDevice/singleDevice";
import Testimonials from "./../components/testimonials/testimonials";
import Top from "./../components/top/top";

import PagesList from "../components/pages-list/pages-list";
import ContactFormHome from './../components/contactFormHome/contactFormHome';
import HowItWorks from './../components/how-it-works/how-it-works';
import testimonialsState from "./../utils/testimonials";

// markup
const IndexPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps2 = {
    layout: 3,
    title: <b style={{ fontWeight: 600 }}>Manage all your online orders from one tablet</b>,
    content: (
      <span style={{ color: '#2E2E2E', opacity: 0.7 }} className="d-none d-md-inline-block">
        We’re changing the way restaurants view and interact with online ordering
        while empowering them to thrive. From mom & pop shops to international chains,
        Orders.co has the tools to tackle the hurdles of an ever changing digital market.
      </span>
    ),
    hideButton: true,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="devices"
            src="../images/Home/Orders-2.png"
            // src="../images/Home/devices.png"
          className="mw-100 d-none d-md-block"
        />
        <div className="d-flex flex-column align-items-center d-md-none">
          <span className="text-center">We’re changing the way restaurants view and interact with online ordering
            while empowering them to thrive.</span>
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="devices"
            src="../images/Home/Orders-2.png"
            // src="../images/Home/devices.png"
            width={266}
            height={200}
            // height={245}
            className="mw-100"
          />
        </div>
      </>
    ),
    styles: {
      background: 'linear-gradient(180deg, rgba(255, 107, 0, 0.4312) 6.77%, rgba(255, 107, 0, 0.098) 52.6%, rgba(255, 255, 255, 0) 90.1%)'
    },
  };

  const partnersProps = {
    image: <StaticImage
      placeholder="none"
      loading="eager"
      alt="business-meeting"
      src="../images/Home/business-meeting.png"
      className="mw-100"
    />,
    title: <>Our Restaurant Partners</>,
    partners: [
      {
        title: <>Slash Pizza</>,
        to: "/customer-stories",
      },
      {
        title: <>C-viche Express</>,
        to: "/customer-stories-cviche",
      },
      {
        title: <>Pops Creamery</>,
        to: "/customer-stories-pops-artisanal-creamery",
      },
      {
        title: <>La Fonda</>,
        to: "",
      },
      {
        title: <>Natalie’s Peruvian</>,
        to: "",
      },
      {
        title: <>Pulp Juice</>,
        to: "",
      },
      // {
      //   title: <>Silver Chicken & Seafood</>,
      //   to: "",
      // },
      {
        title: <>Stirred Coffee House</>,
        to: "",
      },
      {
        title: <>TNT Aloha Cafe</>,
        to: "",
      },
    ]
  }

  const howItWorksProps = {
    title: <>HOW IT WORKS</>,
    subtitle: <>Few Easy Steps and Done</>,
    scrollToForm: scrollToForm,
    items: [
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Create your account"
          src="../images/Home/create-account.png"
          className="mw-100"
        />,
        title: <>Create your account</>,
      },
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Connect Your Menu"
          src="../images/Home/connect-menu.png"
          className="mw-100"
        />,
        title: <>Connect Your Menu</>,
      },
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Integrate with your 3rd Party Delivery App"
          src="../images/Home/integrate-delivery.png"
          className="mw-100"
        />,
        title: <>Integrate with your 3rd Party<br /> Delivery App </>,
      },
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Create a Commission Free Website"
          src="../images/Home/create-website.png"
          className="mw-100"
        />,
        title: <>Create a<br /> Commission Free Website</>,
      },
    ],
  }

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const pagesListProps = {
    title: <>Easy, Simple, Affordable</>,
    items: [
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Chain Restaurants"
          src="../images/Home/chain-restaurants.png"
          className="mw-100"
        />,
        title: <>Chain Restaurants</>,
        description: <>The Orders.co suite was built to facilitate chain restaurant's
          needs like never before. Executives can review analytics from all connected
          locations from one unified administrator account.</>,
        to: "/chain-restaurants",
      },
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Ghost Kitchen"
          src="../images/Home/ghost-kitchen.png"
          className="mw-100"
        />,
        title: <>Ghost Kitchen</>,
        description: <>Orders.co offers an extensive integration system that allows ghost
          kitchens to consolidate all online ordering platforms into one place. </>,
        to: "/ghost-kitchen",
      },
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Independent Restaurants"
          src="../images/Home/independent-restaurants.png"
          className="mw-100"
        />,
        title: <>Independent  Restaurants</>,
        description: <>Orders.co was built from the ground up to support restaurants of
          varying sizes. Our tools help cut costs, save employee time, and improve workflow.
          All while being scalable to match your business's needs.</>,
        to: "/independent-restaurants",
      },
      {
        image: <StaticImage
          placeholder="none"
          loading="eager"
          alt="Q-Commerce"
          src="../images/Home/q-commerce.png"
          className="mw-100"
        />,
        title: <>Q-Commerce</>,
        description: <>Orders.co integration and Master Menu Management allows employees to
          fulfill orders and update item availability in one device. Completely eliminating
          all time wasted jumping between multiple tablets.</>,
        to: "/q-commerce",
      },
    ]
  }

  const qrMenuMaker = {
    layout: 2,
    imageSize: "big",
    scrollToForm: scrollToForm,
    customButton: <>
      <h4 className="text-center">DOWNLOAD FOR FREE ON</h4>
      <div className="d-flex justify-content-center mt-5" style={{ gap: '28px' }}>
        <a className="bg-orange rounded-xl" href="https://play.google.com/store/apps/details?id=com.qrmenumaker" target="_blank" rel="nofollow">
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="get-on-google-play"
            src="../images/QRMenu/get-on-google-play-white.png" />
        </a>
        <a className="bg-orange rounded-xl" href="https://apps.apple.com/us/app/qr-menu-maker/id1643857790" target="_blank" rel="nofollow">
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="download-on-the-app-store"
            src="../images/QRMenu/download-on-the-app-store-white.png" />
        </a>
      </div>
    </>,
    title: (
      <div className="d-flex justify-content-center justify-content-xl-start" style={{ gap: '20px' }}>
        <StaticImage
          placeholder="none"
          loading="eager"
          width={107}
          height={107}
          style={{ marginLeft: '-127px' }}
          className="d-none d-md-block"
          alt="title-logo"
          src="../images/QRMenu/title-logo.png" />
        <StaticImage
          placeholder="none"
          loading="eager"
          width={52}
          height={52}
          className="d-md-none"
          alt="title-logo"
          src="../images/QRMenu/title-logo.png" />
        <div>
          QR Menu Maker App
          <br /> Fast, Easy & Free!
        </div>
      </div>
    ),
    notUseH1: true,
    subtitle: `A Digital Menu For The Digital Age`,
    hideLine: true,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="digital-menu"
        // src="../images/QRMenu/digital-menu.png"
        src="../images/QRMenu/qr-menu-maker.png"
        className="mw-100"
      />
    ),
  };

  const testimonialsProps = {
    layout: 1,
    // layout: 3,
    items: [
      testimonialsState.la_fonda,
      testimonialsState.natalies_peruvian,
      testimonialsState.pulp_juice,
      // testimonialsState.silver_chicken_and_seafood,
      testimonialsState.stirred_coffee_house,
      testimonialsState.tnt_aloha_cafe,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };

  return (
    <>
      <Seo
        title={"Home"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        {/* <Top {...topProps} /> */}
        <Top {...topProps2} />
        <Partners {...partnersProps} />
        <div className="bg-desktop" style={{
          background: `linear-gradient(180deg,
          rgba(255, 255, 255, 0.172) -1.75%,
          rgba(255, 219, 193, 0.172) 24.74%,
          rgba(255, 159, 90, 0.172) 48.06%,
          rgba(217, 217, 217, 0) 100%)`}}>
          <HowItWorks {...howItWorksProps} />
        </div>
        <SingleDevice {...singleDeviceProps} />
        <section className="d-none d-md-block">
          <div className="container">
            <StaticImage
              placeholder="none"
              loading="eager"
              alt="device"
              src="../images/Home/integrations.png"
              className="mw-100"
            />
          </div>
        </section>
        <div className="d-block d-md-none">
          <Integrations scrollToForm={scrollToForm} />
        </div>
        <PagesList {...pagesListProps} />
        <Top {...qrMenuMaker} />
        <Testimonials {...testimonialsProps} />
        <News />
        <div ref={formRef}>
          <ContactFormHome
            titleStyle="small"
            title={
              <span style={{ fontSize: "45px", lineHeight: "68px" }}>
                Manage and Optimize<br />
                Restaurant Orders
              </span>
            }
          ></ContactFormHome>
        </div>
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "home" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default IndexPage;
