import { Link } from "gatsby";
import React from "react";
import * as s from "./how-it-works.module.css";
import TrialButton from './../trialButton/trialButton';

const HowItWorks = ({ title, subtitle, items, scrollToForm }) => {
  return (
    <>
      <section className={s.howItWorks}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!!title &&
                <h2 className={s.title}>
                  {title}
                </h2>
              }
              {!!subtitle &&
                <h3 className={s.subtitle}>
                  {subtitle}
                </h3>
              }
            </div>
            {items?.length > 0 && items.map((item, i) => {
              return <div className="col-12 col-sm-6" key={i}>
                <div className={s.item}>
                  <div className={s.imageWrapper}>
                    <div className={s.number}>{i + 1}</div>
                    {!!item?.image && item.image}
                  </div>
                  {!!item.title && <h5 className={s.itemTitle}>{item.title}</h5>}
                </div>
              </div>
            })}
            <div className="col-12 d-flex justify-content-center mt-5">
              <TrialButton
                scrollToForm={scrollToForm}
                classes={[
                  "trial-button bg-orange cursor-pointer d-inline-flex align-items-center",
                ]}
              >
                GET STARTED FOR FREE
              </TrialButton>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default HowItWorks;
